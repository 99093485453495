import React, { Component } from 'react'

import { Carousel } from 'react-responsive-carousel'

import backgroundImage from '../Assets/back-launch.jpg'

import characterMan1 from '../Assets/h-1.png'
import characterMan2 from '../Assets/h-2.png'
import characterMan3 from '../Assets/h-3.png'

import characterWoman1 from '../Assets/m-1.png'
import characterWoman2 from '../Assets/m-2.png'
import characterWoman3 from '../Assets/m-3.png'

import logoGram from '../Assets/logo-gram.png'

import Logo from '../Components/Logo'
import Button from '../Components/Button'

import { connect } from 'react-redux'
import GameActions from '../Redux/Game'

import './Styles/GeneralStyles.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './Styles/SelectCharacterScreenStyles.css'

class SelectCharacterScreen extends Component {

  constructor(props) {
    super(props)

    this.characters = [
      { image: characterMan1 },
      { image: characterWoman1 },
      { image: characterMan2 },
      { image: characterWoman2 },
      { image: characterMan3 },
      { image: characterWoman3 },
    ]

    this.state = {
      currentCharacter: this.characters[0]
    }
  }


  render() {

    let charactersView = this.characters.map( (character, index) => {
      return (
        <div key={index}>
          <img src={character.image} />
        </div>
      )
    })


    return (
      <div
        style={ { backgroundImage: 'url('+backgroundImage+')', backgroundSize: 'cover', backgroundPosition: 'center' } }
        className="screen">
        <div style={ { paddingTop: 68, paddingBottom: 55 } }>
          <Logo />
        </div>

        <div className="CharacterNameSelected">
          { this.props.user.name }
        </div>
        <div>
          <Carousel
            selectedItem={ this.state.selectedIndex }
            onChange={ index =>  this.setState({ selectedIndex: index, currentCharacter: this.characters[index] }) }
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            emulateTouch
            centerMode
            swipeable
            infiniteLoop >
            { charactersView }
          </Carousel>
        </div>
        <div id="ButtonPlayContainer">
          <Button onPress={ () => {
              this.props.selectCharacter(this.state.currentCharacter)
              this.props.history.push({ pathname: '/sync-data' }) }}>
            JUGAR
          </Button>
        </div>

        <div className="logo-container">
          <img src={ logoGram } />
        </div>

      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    user: state.game.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectCharacter: character => dispatch( GameActions.selectCharacter(character) )
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (SelectCharacterScreen)
