import React, { Component } from 'react'

import backgroundImage from '../Assets/back-launch.jpg'
import LogoGram from '../Assets/logo-gram.png'
import okAudio from '../Assets/sounds/ok.mp3'
import wrongAudio from '../Assets/sounds/wrong.mp3'

import Logo from '../Components/Logo'
import ScoreCard from '../Components/ScoreCard'
import AnswerModal from '../Components/AnswerModal'

import { Config } from '../Config'

import GameActions from '../Redux/Game'

import classNames from 'classnames'
import { Howl } from 'howler'

import { connect } from 'react-redux'



import './Styles/GeneralStyles.css'
import './Styles/QuestionScreenStyles.css'

class QuestionScreen extends Component {

  enums = ['A', 'B', 'C', 'D', 'E', 'F']
  pre = ["ra", "da", "ra", "ta"]
  state = {
    answeredIndex: null
  }

  okAnswer = new Howl({ src: [ okAudio ] })
  wrongAnswer = new Howl({ src: [ wrongAudio ] })

  componentDidMount() {
    this.props.getRandomQuestionOfSelectedCategory()
  }

  selectAnswer(index) {

    this.setState({ answeredIndex: index })

    if (index === this.props.currentQuestion.correct_answer - 1 ) {
      this.okAnswer.play()
      this.props.incrementScore( this.props.currentQuestion.points)
    }else {
      this.wrongAnswer.play()
    }

    setTimeout( () => {

      this.props.incrementAnsweredQuestion()

      if (this.props.answeredQuestions >= Config.questionsPerGame ) {
        this.props.addPlayer(this.props.user, this.props.score)
        return this.props.history.replace('/congrats')
      }

      this.props.history.goBack()
    }, 5000)
  }

  render() {

    const answers = this.props.currentQuestion ? this.props.currentQuestion.answers.map( (answer, idx) => {

      return (
        <div
          key={"Answer-"+idx}
          onClick={ () => this.selectAnswer(idx) }
          className={
            classNames([
              "Answer",
              this.state.answeredIndex !== null && idx === this.props.currentQuestion.correct_answer - 1 ? "RightAnswer" : null,
              this.state.answeredIndex !== null && this.state.answeredIndex === idx && idx !== this.props.currentQuestion.correct_answer - 1 ? "WrongAnswer" : null ]) }>
          <div className="AnswerEnum">
            { this.enums[idx] + ". " }
          </div>
          <div className="AnswerContent">
            { answer.description }
          </div>
        </div>)
    }) : []

    return (
      <div
        style={ { backgroundImage: 'url('+backgroundImage+')', backgroundSize: 'cover', backgroundPosition: 'center' } }
        className="screen">
        <div style={ { paddingTop: 120 } }>
          <Logo />
        </div>
        <div style={ { paddingTop: 80 } } >
          <ScoreCard
            user={ this.props.user}
            score={this.props.score}
            character={ this.props.character } />
        </div>


        <div style={ { paddingTop: 80 } } className="QuestionContainer">
          <div className="Question">
            { this.props.currentQuestion ? this.props.currentQuestion.description : null}
          </div>
          <div className="Answers">
            { answers }
          </div>
        </div>

        <div className="GameInfoContainer">
          <div className="GameQuestionNumber">
            { this.props.answeredQuestions + 1 + this.pre[this.props.answeredQuestions]} pregunta
          </div>
          <div className="logo-container">
            <img src={ LogoGram } />
          </div>
          <div className="GameCurrentCategoryName">
            { this.props.selectedCategory ? this.props.selectedCategory.name : null }
          </div>
        </div>
        { this.props.currentQuestion &&
          <AnswerModal
            right={ (this.state.answeredIndex === (this.props.currentQuestion.correct_answer - 1)) }
            open={ this.state.answeredIndex !== null } />
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    score: state.game.score,
    user: state.game.user,
    character: state.game.character,
    selectedCategory: state.game.selectedCategory,
    currentQuestion: state.game.currentQuestion,
    answeredQuestions: state.game.answeredQuestions,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getRandomQuestionOfSelectedCategory: () =>
      dispatch( GameActions.getRandomQuestionOfSelectedCategory() ),
    incrementAnsweredQuestion: () =>
      dispatch( GameActions.incrementAnsweredQuestion() ),
    incrementScore: scoreEarned =>
      dispatch( GameActions.incrementScore(scoreEarned) ),
    addPlayer: (user, score) =>
      dispatch( GameActions.addPlayer(user, score) ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (QuestionScreen)
